import React, { useCallback, useEffect, useState } from "react";
import { addMemberToTheTeam, resetError } from "../redux/reducers/teamReducer";
import MyInput from "../UI/Input/MyInput";
import MyModal from "../UI/MyModal/MyModal";
import classes from "./AddMemberModal.module.css";
import { useDispatch, useSelector } from "react-redux";

const AddMemberModal = ({ visible, setVisible }) => {
  const [email, setEmail] = useState("");

  const { teamMembers, loading, error } = useSelector(
    (state) => state.teamReducer
  );
  
  const dispatch = useDispatch();

  const sendForm = (e) => {
    e.preventDefault();

    if (email) {
      let data = {
        email,
        role: "ADMIN", // hardcoded for now
      };

      dispatch(addMemberToTheTeam(data));
    }
  };

  const closeModal = useCallback(() => {
    dispatch(resetError());
    setVisible(false);
    setEmail("");
  }, [setVisible, dispatch]);

  useEffect(() => {
    closeModal();
  }, [teamMembers?.length, closeModal]);

  return (
    <MyModal visible={visible} setVisible={closeModal}>
      <form onSubmit={sendForm} className={classes.form}>
        <label htmlFor="email" className={classes.label}>
          Enter Email
          <MyInput
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email"
            className={classes["email-input"]}
          />
          <p className={classes["error-message"]}>{error}</p>
        </label>
        <button
          style={{ marginTop: "10px", width: "100%" }}
          className={"btn btn-lg btn-dark px-md-4 mt-lg-3"}
          disabled={!email || loading}
        >
          Submit
        </button>
        <button
          type="button"
          onClick={closeModal}
          style={{ marginTop: "10px", width: "100%" }}
          className={classes["cancel-button"]}
        >
          Cancel
        </button>
      </form>
    </MyModal>
  );
};

export default AddMemberModal;
