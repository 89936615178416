import React, { useEffect, useState } from "react";
import MyForms from "../pages/Form/MyForms";
import NewForm from "../pages/NewForm";
import Profile from "../pages/Profile/Profile";
import Settings from "../pages/Profile/Settings";
import HomeContent from "./HomeContent";
import { useParams } from "react-router";
import Response from "../pages/Response/Response";
import ResponseTrashed from "../pages/Response/ResponseTrashed";
import Statistics from "../pages/Response/Statistics";
import CustomerSupport from "../pages/CustomerSupport";
import Members from "../pages/Members";
import Suggestions from "../pages/Suggestions";
import Team from "../pages/Team";

const Content = () => {
  const [pageName, setPageName] = useState("");
  const params = useParams();

  function hasNewForm(name) {
    if (params.hasOwnProperty("string") && params.hasOwnProperty("id")) {
      return false;
    }
    return /\b(form_\w+)\b/i.test(name);
  }

  useEffect(() => {
    var pageNowName = window.location.pathname;
    var splittedPagNow = pageNowName.split("/");
    if (splittedPagNow[1] == "form" && splittedPagNow.length == 3) {
      setPageName("/form_" + splittedPagNow[splittedPagNow.length - 1]);
    } else if (splittedPagNow[1] == "responses") {
      setPageName("/response");
    } else if (splittedPagNow[1] == "responsestrashed") {
      setPageName("/responsetrashed");
    } else if (splittedPagNow[1] == "statistics") {
      setPageName("/statistics");
    } else {
      setPageName(pageNowName);
    }
  }, []);
  return (
    <div>
      {pageName == "/suggestions" ? (
        <Suggestions />
      ) : pageName == "" || pageName == undefined ? (
        <HomeContent key={0} />
      ) : pageName == "/profile" ? (
        <Profile key={1} />
      ) : pageName == "/settings" ? (
        <Settings key={2} />
      ) : pageName == "/response" ? (
        <Response key={3} />
      ) : pageName == "/responsetrashed" ? (
        <ResponseTrashed key={4} />
      ) : pageName == "/statistics" ? (
        <Statistics key={5} />
      ) : pageName == "/support" ? (
        <CustomerSupport key={6} />
      ) : pageName == "/team" ? (
        <Team key={7} />
      ) : pageName == "/members" ? (
        <Members key={8} />
      ) : hasNewForm(pageName) ? (
        <NewForm key={9} />
      ) : (
        <MyForms key={10} />
      )}
    </div>
  );
};

export default Content;
