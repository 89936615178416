import { configureStore } from "@reduxjs/toolkit";
import formReducer from "./reducers/formReducer";
import infoReducer from "./reducers/infoReducer";
import responseReducer from "./reducers/responseReducer";
import dataReducer from "./reducers/dataReducer";
import adminReducer from "./reducers/adminReducer";
import teamReducer from "./reducers/teamReducer";

export const store = configureStore({
    reducer: {
      formReducer,
      infoReducer,
      responseReducer,
      dataReducer,
      adminReducer,
      teamReducer
    },
})