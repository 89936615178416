import React from "react";
import classes from "./MyInput.module.css";

const MyInput = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input ref={ref} className={`${classes.myInput} ${className}`} {...props} />
  );
});

export default MyInput;
