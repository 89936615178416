import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
    getAdminUsersInfo,
    getAdminInfo,
    getAdmNotSolvedMessageInfo,
    getAdmSolvedMessageInfo,
    getAdmNotSolvedPaymentInfo,
    getAdmSuspendedInfo,
    getAdmSolvedPaymentInfo,
    getAdmPaymentInfo,
    getAdmPayingInfo,
    getAdmPayingInAllInfo,
    getAdmNotSolvedCustomerInfo,
    getAdmSolvedCustomerInfo,
    getAdminFormInfo,
    getAdminMessagesInfo,
    getAdminResponsesInfo,
    getAdminCustomersInfo,
    getAdminDataByMonthInfo,
    getErrorLogs,
    getAdminFormReportsInfo,
    getAdminSuggestionsInfo,
} from '../features/dataApi';

const initialState = {
    info: {},
    users: {},
    forms: {},
    paying: {},
    globalDataRange: {},
    globalSearchText: '',
    notSolvedMessages: {},
    payingInAllInfo: {},
    solvedMessages: {},
    suspended: {},
    notSolvedPayments: {},
    solvedPayments: {},
    payment: {},
    notSolvedCustomers: {},
    solvedCustomers: {},
    messages: {},
    customers: {},
    responses: {},
    dataByMonth: {},
    errorLogs: '',
    searchData: '',
    payingInfo: false,
    isEdited: false,
    infoLoading: false,
    usersLoading: false,
    adminLoading: false,
    paymentLoading: false,
    suspendedLoading: false,
    formsLoading: false,
    messagesLoading: false,
    errorLogsLoading: false,
    payingLoading: false,
    notSolvedMessagesLoading: false,
    solvedMessagesLoading: false,
    notSolvedPaymentsLoading: false,
    solvedPaymentsLoading: false,
    notSolvedCustomersLoading: false,
    solvedCustomersLoading: false,
    customersLoading: false,
    responsesLoading: false,
    dataByMonthLoading: false,
    reports: {},
    suggestions: {},
    globalSearchTextPayment:'',
    globalPaymentTabName: 'Paid',
    adminUserTabName:'Active',
}

export const getAdmInfo = createAsyncThunk(
    '/api/getAdminInfo',
    async () => {
        const response = await getAdminInfo()
        return response.data
    }
)

export const getNotSolvedMessages = createAsyncThunk(
    '/api/getNotSolvedMessageInfo',
    async (page) => {
        const response = await getAdmNotSolvedMessageInfo(page)
        return response.data
    }
)

export const getSuspended = createAsyncThunk(
    '/api/getSuspendedInfo',
    async (page) => {
        const response = await getAdmSuspendedInfo(page)
        return response.data
    }
)

export const getSolvedMessages = createAsyncThunk(
    '/api/getSolvedMessageInfo',
    async (page) => {
        const response = await getAdmSolvedMessageInfo(page)
        return response.data
    }
)

export const getNotSolvedPayments = createAsyncThunk(
    '/api/getNotSolvedPaymentInfo',
    async (page) => {
        const response = await getAdmNotSolvedPaymentInfo(page)
        return response.data
    }
)

export const getSolvedPayments = createAsyncThunk(
    '/api/getSolvedPaymentInfo',
    async (page) => {
        const response = await getAdmSolvedPaymentInfo(page)
        return response.data
    }
)

export const getPaying = createAsyncThunk(
    '/api/getPayingInfo',
    async (page) => {
        const response = await getAdmPayingInfo(page)
        return response.data
    }
)

export const getPaayingInAllInfo = createAsyncThunk(
    '/api/getPayingInAllInfo',
    async (data) => {
        const response = await getAdmPayingInAllInfo(data)
        return response.data
    }
)

export const getNotSolvedCustomers = createAsyncThunk(
    '/api/getNotSolvedCustomerInfo',
    async (page) => {
        const response = await getAdmNotSolvedCustomerInfo(page)
        return response.data
    }
)

export const getSolvedCustomers = createAsyncThunk(
    '/api/getSolvedCustomerInfo',
    async (page) => {
        const response = await getAdmSolvedCustomerInfo(page)
        return response.data
    }
)

export const getErrorLogsData = createAsyncThunk(
    '/api/getErrorLogs',
    async () => {
        const response = await getErrorLogs()
        return response.data
    }
)

export const getAdminUsers = createAsyncThunk(
    '/api/getAdminUserDatas?page=',
    async (data) => {
        const response = await getAdminUsersInfo(data)
        return response.data
    }
)

export const getAdminForms = createAsyncThunk(
    '/api/getFormsDatasForAdmin?page=',
    async (page) => {
        const response = await getAdminFormInfo(page)
        return response.data
    }
)

export const getAdmDataByMonth = createAsyncThunk(
    '/api/getDataByMonth',
    async () => {
        const response = await getAdminDataByMonthInfo()
        return response.data
    }
)

export const getAdmPayment = createAsyncThunk(
    '/api/getPaymentInfo?page=',
    async (page) => {
        const response = await getAdmPaymentInfo(page)
        return response.data
    }
)

export const getAdmMessages = createAsyncThunk(
    '/api/getMessagesDatasForAdmin?page=',
    async (page) => {
        const response = await getAdminMessagesInfo(page)
        return response.data
    }
)

export const getAdmCustomers = createAsyncThunk(
    '/api/getCustomersDatasForAdmin?page=',
    async (page) => {
        const response = await getAdminCustomersInfo(page)
        return response.data
    }
)

export const getAdmResponses = createAsyncThunk(
    '/api/getResponsesDatasForAdmin?page=',
    async (page) => {
        const response = await getAdminResponsesInfo(page)
        return response.data
    }
)

export const getAdminReports = createAsyncThunk(
    '/api/getReportsForAdmin?page=',
    async (page) => {
        const response = await getAdminFormReportsInfo(page)
        return response.data
    }
)

export const getAdminSuggestions = createAsyncThunk(
    '/api/getSuggestionsForAdmin?page=',
    async (page) => {
        const response = await getAdminSuggestionsInfo(page)
        return response.data
    }
)
export const dataSlice = createSlice({
    name: 'dataReducer',
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.info = {...action.payload}
        },
        setForms: (state, action) => {
            state.forms = action.payload
        },
        setPaying: (state, action) => {
            state.paying = action.payload
        },
        setDataByMonth: (state, action) => {
            state.dataByMonth = action.payload
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setErrorLogs: (state, action) => {
            state.errorLogs = action.payload
        },
        setIsEdited: (state, action) => {
            state.isEdited = action.payload
        },
        setNotSolvedMessages: (state, action) => {
            state.notSolvedMessages = action.payload
        },
        setSolvedMessages: (state, action) => {
            state.solvedMessages = action.payload
        },
        setNotSolvedPayments: (state, action) => {
            state.notSolvedPayments = action.payload
        },
        setSuspended: (state, action) => {
            state.suspended = action.payload
        },
        setSolvedPayments: (state, action) => {
            state.solvedPayments = action.payload
        },
        setGlobalDateRange: (state, action) => {
            state.globalDataRange = action.payload
        },
        setGlobalSearchText: (state, action) => {
            state.globalSearchText = action.payload
        },
        setPayment: (state, action) => {
            state.payment = action.payload
        },
        setNotSolvedCustomers: (state, action) => {
            state.notSolvedCustomers = action.payload
        },
        setSolvedCustomers: (state, action) => {
            state.solvedCustomers = action.payload
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        setCustomers: (state, action) => {
            state.customers = action.payload
        },
        setResponses: (state, action) => {
            state.responses = action.payload
        },
        setSearchData: (state, action) => {
            state.searchData = action.payload
        },
        setPayingInfo: (state, action) => {
            state.payingInfo = action.payload
        },
        setPayingInAllInfo: (state, action) => {
            state.payingInAllInfo = action.payload
        },
        setAdminLoading: (state, action) => {
            state.adminLoading = action.payload
        },
        setReports: (state, action) => {
            state.reports = action.payload
        },
        setSuggestions: (state, action) => {
            state.suggestions = action.payload
        },
        setAdminUserTabName :(state, action) => {
            state.adminUserTabName = action.payload
        },
        setGlobalSearchTextPayment :(state, action) => {
            state.globalSearchTextPayment = action.payload
        },
        setGlobalPaymentTabName :(state, action) => {
            state.globalPaymentTabName = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdmInfo.fulfilled, (state, action) => {
                state.infoLoading = false
                dataSlice.caseReducers.setAdmin(state, action)
            })
            .addCase(getAdmInfo.pending, (state, action) => {
                state.infoLoading = true
            })
            .addCase(getAdminUsers.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setUsers(state, action)
            })
            .addCase(getAdminUsers.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getPaying.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setPaying(state, action)
            })
            .addCase(getPaying.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getPaayingInAllInfo.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setPayingInAllInfo(state, action)
            })
            .addCase(getPaayingInAllInfo.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getNotSolvedMessages.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setNotSolvedMessages(state, action)
            })
            .addCase(getNotSolvedMessages.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getSolvedMessages.fulfilled, (state, action) => {
                state.solveadminLoadingdMessagesLoading = false
                dataSlice.caseReducers.setSolvedMessages(state, action)
            })
            .addCase(getSolvedMessages.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getSuspended.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setSuspended(state, action)
            })
            .addCase(getSuspended.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getNotSolvedPayments.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setNotSolvedPayments(state, action)
            })
            .addCase(getNotSolvedPayments.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getSolvedPayments.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setSolvedPayments(state, action)
            })
            .addCase(getSolvedPayments.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getAdmPayment.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setPayment(state, action)
            })
            .addCase(getAdmPayment.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getNotSolvedCustomers.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setNotSolvedCustomers(state, action)
            })
            .addCase(getNotSolvedCustomers.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getSolvedCustomers.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setSolvedCustomers(state, action)
            })
            .addCase(getSolvedCustomers.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getAdminForms.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setForms(state, action)
            })
            .addCase(getAdmMessages.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setMessages(state, action)
            })
            .addCase(getAdmCustomers.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setCustomers(state, action)
            })
            .addCase(getAdmCustomers.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getAdmDataByMonth.fulfilled, (state, action) => {
                state.dataByMonthLoading = false
                dataSlice.caseReducers.setDataByMonth(state, action)
            })
            .addCase(getAdmDataByMonth.pending, (state, action) => {
                state.dataByMonthLoading = true
            })

            .addCase(getErrorLogsData.fulfilled, (state, action) => {
                state.errorLogsLoading = false
                dataSlice.caseReducers.setErrorLogs(state, action)
            })
            .addCase(getErrorLogsData.pending, (state, action) => {
                state.errorLogsLoading = true
            })

            // .addCase(getAdmMessages.pending, (state, action) => {
            // 	state.messagesLoading = true
            // })
            .addCase(getAdmResponses.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setResponses(state, action)
            })
            .addCase(getAdmResponses.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getAdminReports.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setReports(state, action)
            })
            .addCase(getAdminReports.pending, (state, action) => {
                state.adminLoading = true
            })
            .addCase(getAdminSuggestions.fulfilled, (state, action) => {
                state.adminLoading = false
                dataSlice.caseReducers.setSuggestions(state, action)
            })
            .addCase(getAdminSuggestions.pending, (state, action) => {
                state.adminLoading = true
            })
    },
});

export const {
    setPayingInAllInfo,
    setGlobalSearchText,
    setGlobalDateRange,
    setAdmin,
    setUsers,
    setForms,
    setIsEdited,
    setMessages,
    setResponses,
    setCustomers,
    setSearchData,
    setNotSolvedMessages,
    setSolvedMessages,
    setNotSolvedPayments,
    setSolvedPayments,
    setNotSolvedCustomers,
    setSolvedCustomers,
    setPayment,
    setErrorLogs,
    setPaying,
    setPayingInfo,
    setAdminLoading,
    setSuspended,
    setReports,
    setSuggestions,
    setAdminUserTabName,
    setGlobalSearchTextPayment,
    setGlobalPaymentTabName,
} = dataSlice.actions;

export const getAdminUsersData = (state) => state.adminReducer.users;
export const getAdminSearchData = (state) => state.adminReducer.searchData;
export const getAdminUsersDataLoading = (state) => state.adminReducer.usersLoading;
export const getAdminFormsData = (state) => state.adminReducer.forms;
export const getNotSolvedMessageData = (state) => state.adminReducer.notSolvedMessages;
export const getSolvedMessageData = (state) => state.adminReducer.solvedMessages;
export const getPayingData = (state) => state.adminReducer.paying;
export const getGlobalDateRangeInfo = (state) => state.adminReducer.globalDataRange;
export const getGlobalSearchTextInfo = (state) => state.adminReducer.globalSearchText;
export const getPayingDataLoading = (state) => state.adminReducer.payingLoading;
export const getAdminDataLoading = (state) => state.adminReducer.adminLoading;
export const getPayingInfo = (state) => state.adminReducer.payingInfo;
export const getPayingInAllInfo = (state) => state.adminReducer.payingInAllInfo;
export const getNotSolvedPaymentData = (state) => state.adminReducer.notSolvedPayments;
export const getSuspendedData = (state) => state.adminReducer.suspended;
export const getSolvedPaymentData = (state) => state.adminReducer.solvedPayments;
export const getNotSolvedCustomerData = (state) => state.adminReducer.notSolvedCustomers;
export const getSolvedCustomerData = (state) => state.adminReducer.solvedCustomers;
export const getAdminDataByMonth = (state) => state.adminReducer.dataByMonth;
export const getAdminDataByMonthLoading = (state) => state.adminReducer.dataByMonthLoading;
export const getAdminMessagesData = (state) => state.adminReducer.messages;
export const getAdminPaymentData = (state) => state.adminReducer.payment;
export const getAdminResponsesData = (state) => state.adminReducer.responses;
export const getAdminCustomersData = (state) => state.adminReducer.customers;
export const getAdminInfoData = (state) => state.adminReducer.info;
export const getAdminInfoDataLoading = (state) => state.adminReducer.infoLoading;
export const getAdminErrorLogs = (state) => state.adminReducer.errorLogs;
export const getAdminErrorLogsLoading = (state) => state.adminReducer.errorLogsLoading;
export const getAdminReportsData = (state) => state.adminReducer.reports;
export const getAdminSuggestionsData = (state) => state.adminReducer.suggestions;
export const getAdminUserTabNameData = (state) => state.adminReducer.adminUserTabName;
export const getGlobalSearchTextPaymentData = (state) => state.adminReducer.globalSearchTextPayment;
export const getGlobalPaymentTabNameData = (state) => state.adminReducer.globalPaymentTabName;

export default dataSlice.reducer;
