import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembers } from "../redux/reducers/teamReducer";
import TeamMemberCard from "../components/common/TeamMemberCard";
import AddMemberModal from "../components/AddMemberModal";

const Team = () => {
  const [visible, setVisible] = useState(false);

  const { teamMembers, loading } = useSelector((state) => state.teamReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!teamMembers) {
      dispatch(getTeamMembers());
    }
  }, [dispatch, teamMembers]);

  return (
    <div>
      <AddMemberModal visible={visible} setVisible={setVisible} />
      <div className="tabContent preview-form-pages px-3 px-xxl-5 py-3 py-lg-4 border-bottom border-gray-200 after-header">
        <h1 className="h2 mb-0 lh-sm account-tabname">Team</h1>
        <button
          type="button"
          onClick={() => setVisible(true)}
          className="btn btn-xl btn-warning text-nowrap ms-xl-2 new-form-btn"
          disabled={loading}
        >
          <svg
            className="me-2"
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            viewBox="0 0 14 14"
          >
            <rect
              data-name="Icons/Tabler/Add background"
              width={14}
              height={14}
              fill="none"
            />
            <path
              d="M6.329,13.414l-.006-.091V7.677H.677A.677.677,0,0,1,.585,6.329l.092-.006H6.323V.677A.677.677,0,0,1,7.671.585l.006.092V6.323h5.646a.677.677,0,0,1,.091,1.348l-.091.006H7.677v5.646a.677.677,0,0,1-1.348.091Z"
              fill="#000"
            />
          </svg>
          Add member
        </button>
      </div>

      {teamMembers !== undefined && teamMembers.length === 0 && !loading && (
        <div className="form-search my-5">
          <p className="form-search text-center">
            You don't have team members yet, add a member.
          </p>
          <div className="form-search-image">
            <img src="/img/BG/empty-members.png" alt="No members" />
          </div>
        </div>
      )}

      {teamMembers?.length > 0 && (
        <div className="flex p-4">
          {teamMembers.map((member) => (
            <TeamMemberCard member={member} key={member._id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Team;
